<template>
  <UCard
    :ui="{
      root: 'overflow-hidden bg-neutral-900/40',
      body: 'p-0 sm:p-0 grid grid-cols-2 md:grid-cols-4 divide-x divide-y md:divide-y-0 divide-neutral-800',
    }"
  >
    <div
      v-for="(stat, index) in animatedStats"
      :key="index"
      class="flex flex-col items-center justify-center p-4"
    >
      <div
        v-if="stat.icon.includes('star')"
        class="relative isolate flex w-full items-center justify-center"
      >
        <UIcon
          v-for="i in 5"
          :key="i"
          :name="stat.icon"
          class="size-5 shrink-0 text-yellow-400"
        />
      </div>
      <UIcon v-else :name="stat.icon" class="size-6 shrink-0 text-yellow-400" />
      <div class="text-center">
        <h3 class="mt-1 text-2xl font-extrabold text-white">
          {{ stat.displayValue }}{{ stat.enable_plus_icon ? '+' : '' }}
        </h3>
        <p class="text-sm font-extrabold text-yellow-400 uppercase">
          {{ stat.label }}
        </p>
      </div>
    </div>
  </UCard>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, watch } from 'vue';

const props = defineProps({
  stats: {
    type: Array,
    default: () => [],
  },
});
const animatedStats = ref([]);

// Watch for changes to props.stats and initialize the display values
watch(
  () => props.stats,
  (newStats) => {
    // Clone the stats with displayValue initialized to 0 for each item
    animatedStats.value = newStats.map((stat) => ({
      ...stat,
      displayValue: 0,
    }));
  },
  { immediate: true } // Run immediately on initial load
);

// Function to animate the count-up
function animateStat() {
  animatedStats.value.forEach((stat) => {
    const increment = Math.ceil(stat.value / 500); // Total duration around 3 seconds
    const animate = () => {
      stat.displayValue += increment;
      if (stat.displayValue < stat.value) {
        setTimeout(animate, 0);
      } else {
        stat.displayValue = stat.value; // Cap at target value
      }
    };
    animate();
  });
}

// Run animation only on the client side
onBeforeMount(() => {
  if (import.meta.client) {
    animateStat();
  }
});
</script>
